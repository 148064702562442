html, body {
  margin: 0;
  padding: 0;
  max-height: 100%;
}

div:has(.ap-modal) {
  --bs-modal-width: 95%;
}

header {
  background-color: #00446A;
  color: #fff;
  height: 280px;
  display: flex;
  flex-direction: column;
}

main {
  position: absolute;
  top: 280px;/*vasu rao changed from 250 */
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  font-size: smaller!important;
}

nav {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: default;
}

nav > h3 {
  font-weight: bold;
  margin-bottom: 0;
}

nav > img {
  margin-right: 0.5rem;
}

th, td {
  min-width: max-content;
  cursor: default;
}

.ap-modal {
  box-shadow: 0px 0px 30px #000;
  border-radius: 0;
}

.ap-modal h2 {
  font-size: large;
}

.appliedFilterLine {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.appliedFilterLine > span:first-child > span:first-child {
  font-weight: bold;
}

.appliedFilterLine > span:last-child {
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
}

.appliedFilterLine > span:last-child:hover {
  text-shadow: 0px 0px 3px #ccc;
}

.branchActionLine {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem 0 0;
}

.branchActionLine:hover {
  background-color: #F2F2F2;
}

.columnProfileLine {
  display: grid;
  grid-template-columns: minmax(100px, 300px) 100px 100px 150px;
  text-align: right;
  align-items: flex-start;
}

.columnProfileLine span:first-child {
  text-align: left;
}

.columnProfileLine button, 
.columnSettingsButtons button,
#branchActions button,
#priorityThresholds button,
#customAssignments button {
  background-color: transparent;
  color: #3b9cd4;
  text-decoration: underline;
  border: none;
  transition-duration: 100ms;
  text-align: right;
  padding: 0;
}

.columnProfileLine button:hover,
.columnSettingsButtons button:hover,
#branchActions button:hover,
#priorityThresholds button:hover,
#customAssignments button:hover {
  color: #337196;
}

.columnSettingsButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.columnSettingsLine {
  display: grid;
  grid-template-columns: 20px minmax(150px, 1fr) 1fr 0.5fr 0.5fr minmax(100px, 1fr);
  align-items: center;
  gap: 1rem;
  cursor: default;
}
.column {
  float: left;
  width: 100%;
}
.columnSchemaLine {
  display: grid;
  grid-template-columns: 150px 250px 150px 300px ;
  align-items: center;
  gap: 5rem;
  cursor: default;
}
.columnSettingsLine:hover {
  background-color: #F2F2F2;
}
.columnSchemaLine:hover {
  background-color: #F2F2F2;
}

.columnSettingsLine span:first-child {
  cursor: pointer;
}


.columnSettingsLine input[type="checkbox"], .columnSettingsLine input[type="number"] {
  text-align: center;
  margin: auto;
}

.columnSettingsLine:first-of-type {
  text-align: center;
  font-weight: bold;
}

.columnSchemaLine:first-of-type {
  text-align: lef;
  font-weight: bold;
}

.columnSettingsLine:first-of-type span:nth-child(n+2):nth-child(-n+3) {
  text-align: left;
}

.columnSettingsLine label {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.columnSettingsLine input[type="number"] {
  max-width: 100px;
}

.customAssignmentLine {
  display: grid;
  grid-template-columns: 200px 200px 200px 50px;
  gap: 1rem;
}

.customAssignmentLine:first-child {
  position: sticky;
  top: 0;
  background: #fff;
  padding-bottom: 0.5rem;
}

.customAssignmentLine span {
  font-weight: bold;
}

.dropdownMenu {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  left: 0;
  width: 100%;
  transform: translateY(-2em);
  z-index: -1;
  display: flex;
  flex-direction: column;
  transition: all 300ms ease-in-out 0s, z-index 0s linear 100ms;
}

.dropdownMenu button {
  transition: color 50ms ease 0s;
  width: max-content;
  border: none;
  place-self: flex-end;
}

.dropdownMenu button:hover {
  color: #00446A;
}

.filterBox {
  width: max-content;
  max-height: 150px;
  min-width: 100px;
  max-width: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: default;
  background-color: #F2F2F2;
}

.filterBox:has(#appliedFiltersBox) {
  min-width: 150px;
  max-width: 350px;
}

.filterBox:has(#districtBox) {
  max-height: unset;
  margin-bottom: 1rem;
}

.filterBox > span {
  color: #00446A;
  font-weight: bold;
  text-align: center;
}

.filterBox > div {
  overflow: auto;
  color: #000;
  height: 100%;
  position: relative;
  font-size: smaller;
}

.filterBox > label {
  font-size: small;
  color: black;
  font-style: italic;
  padding-left: 0.2rem;
  display: flex;
  gap: 0.2rem;
}

.filterBox > div.buttonBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
}

div.buttonBox > button {
  font-size: small;
  width: 145px;
  padding: 0;
  height: min-content;
  border: none;
  box-shadow: 1px 1px 5px #999;
}

div.buttonBox > button:hover {
  background-color: #ddd;
}

div.buttonBox > button:active {
  transform: translate(0px, 1px);
}

div.buttonBox > button.active {
  background-color: #00446A;
  color: #fff;
}

.filterBox.buttonBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.filterBox.buttonBox button {
  margin: 0.25rem auto;
  width: 90%;
  background-color: #00446A;
  color: #fff;
  text-align: center;
  height: 40px;
  transition-duration: 50ms;
}

.filterBox.buttonBox button:hover {
  background-color: #084f79;
}

.filterContainer {
  display: flex;
  padding: 1rem;
  padding-top: 0;
  gap: 0.5rem;
  overflow-x: auto;
}

.filterContainer > .filterContainer {
  justify-content: space-between;
  flex-wrap: wrap;
  overflow-x: unset;
}

.invalid-input {
  border: 2px solid red;
  box-shadow: 0px 3px 5px rgb(255, 95, 95);
}

.invalid-input:focus {
  outline: none;
}

.loading {
  --bs-spinner-width: 7rem;
  --bs-spinner-height: 7rem;
  --bs-spinner-border-width: 0.5rem;
  color: #00446A;
  width: max-content;
  margin: auto;
  position: relative;
  top: 100px;
}

.recordContainer > span {
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: larger;
}

#appliedFiltersBox {
  padding: 0.5rem;
  overflow: auto;
}

#areaBox {
  overflow: auto;
}

#areaBox > label {
  display: flex;
  width: 100%;
  padding: 0.1rem;
  padding-left: 0.3rem;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

#areaBox > label:hover {
  background-color: #ddd;
}

#areaBox > label:has(input:checked) {
  background-color: #00446A;
  color: #fff;
}

#auditBox {
  margin-left: 2rem;
  margin-right: auto;
  display: flex;
  gap: 3rem;
  padding: 0.5rem;
  font-weight: bold;
}

#branchActions > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#branchActions > div > div:first-child {
  display: flex;
  justify-content: space-between;
}

#branchActions input, 
#priorityThresholds input,
#customAssignments input {
  border: none;
  border-bottom: 1px solid black;
}

#branchActions label, 
#priorityThresholds label,
#customAssignments label {
  display: flex;
  gap: 2rem;
}

#branchActionLineContainer {
  max-height: 200px;
  overflow-y: auto;
}

#columnSettings {
  display: flex;
  align-items: stretch;
  gap: 2rem;
}

#columnSettings > div {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#columnSettings div div:has(.columnSettingsLine) {
  display: grid;
  grid-auto-rows: 35px;
  overflow: auto;
  height: 700px;
}

#columnSettings div div:has(.columnSettingsLine) div:first-child {
  position: sticky;
  top: 0;
  background-color: #fff;
}

#columnSettings > div > h2 {
  background: #fff;
  padding: 1rem 0;
  font-size: large;
}

#columnSettings .buttonBox {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin: 0.5rem 0;
}

#customAssignments select {
  border: none;
}

#customAssignmentsContainer {
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
}

#districtBox {
  text-align: center;
}

#districtBox > select, #districtBox > span {
  border: none;
  height: min-content;
  background-color: transparent;
  font-size: 2rem; /* vasu rao changed from 2.5*/
  font-weight: bold;
  color: #00446A;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-60%);
}

#groupSettingsContainer {
  display: flex;
  gap: 3rem;
}

#groupSettingsContainer div:last-child {
  flex-grow: 1;
}

#filtersHeader {
  width: 100%;
  background-color: #F2F2F2;
  text-align: center;
  color: #00446A;
  font-weight: bold;
}

#noAccessPage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

#noAccessPage p {
  text-align: center;
  max-width: 650px;
}

#noAccessPage p > div > div {
  display: grid;
  grid-template-columns: 150px 500px;
}

#noAccessPage p > div > div > span:first-child {
  text-align: left;
}

#noAccessPage p > div > div > span:last-child {
  text-align: right;
}

#poTypeBox {
  max-width: 100px; /*Vasu rao changed from 150*/
}

#priorityBox {
  max-width: 100px; /* vasu rao changed from 150 */
}

#priorityThresholds {
  margin-bottom: 1rem;
}

#priorityThresholds > div {
  display: flex;
  flex-direction: column;
}

#priorityThresholds > div > div {
  display: grid;
  grid-template-columns: 50px 100px 200px 100px 50px;
  gap: 1rem;
}

#profileButton {
  background-color: #F2F2F2;
  padding: 0.3rem;
  color: #000;
  box-shadow: inset 0px 0px 6px #aaa;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 0.5rem;
  font-size: small;
  font-weight: bold;
  position: relative;
}

#profileButton > span:nth-child(2) {
  font-size: smaller;
}

#profileButton .dropdownMenu {
  text-align: right;
  background-color: #F2F2F2;
  box-shadow: 0px 3px 6px #333;
  padding: 0.3rem;
  cursor: default;
}

label#saveAsNewProfileName {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 1rem;
}

label#saveAsNewProfileName input {
  flex-grow: 1;
}

#profileButton:hover .dropdownMenu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

#scenarioBox {
  overflow: auto;
  gap: 0.5rem;
}

#searchBox {
  display: flex;
  flex-direction: column;
}

#searchBox select {
  background-color: transparent;
}

#searchBox input {
  border-radius: 0%;
  border: 1px solid #ccc;
}

#searchBox select, #searchBox input {
  padding: 0.25rem;
  width: 100%;
}
