:root {
  --dg-row-height:auto;
  --dg-col-width:100px;
}

.data-grid {
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.data-grid-row, .data-grid-header-row {
  display: grid;
  justify-content: stretch;
  border-bottom: 1px solid black;
}

.data-grid-row {
  cursor: default;
  z-index:0;
}

.data-grid-header-row {
  position: sticky;
  top: 0;
  inset-block-start: 0;
  opacity: 1;
  background-color: #fff;
  text-align: center;
  font-weight: bold;
  z-index: 1;
}

.data-grid-cell, .data-grid-header-cell {
  border-right: 1px solid black;
  padding: 0.25rem;
}

.data-grid-cell {
  overflow: auto;
  display: flex;
  align-items: flex-start;
}

.data-grid-header-cell {
  cursor: grab;
  position: relative;
  z-index: 0;
}

.data-grid-header-cell:hover {
  background-color: #fafafa;
}

.data-grid-header-cell-filter {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #eee;
  cursor: pointer;
}

.data-grid-header-cell-filter-options {
  font-weight: normal;
  font-size: smaller;
  text-align: left;
  cursor: default;
  max-height: 250px;
  width: max-content;
  max-width: 200px;
  border: 1px solid #eee;
  background-color: #fff;
  overflow-y: scroll;
  padding: 0.5rem;
  position: absolute;
  top: 20px;
  right: 0;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  box-shadow: 0px 0px 4px #aaa;
}

.data-grid-header-cell-filter-options label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.data-grid-header-cell-filter-options input {
  margin-right: 0.25rem;
  cursor: pointer;
}

.dg-text-cell, .dg-select-cell {
  border: none;
  height: 100%;
  width: 100%;
}

.dg-hc-b, .dg-fc-b {
  position: absolute;
  right: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #eee;
  background-color: #eee;
  box-shadow: inset 0px 0px 4px #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dg-hc-b-filtered {
  position: absolute;
  right: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #eee;
  background-color: #f80909;
  box-shadow: inset 0px 0px 4px #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dg-hc-b {
  bottom: 0;
}

.dg-fc-b {
  top: 0;
}

.dg-hc-b path, .dg-fc-b path, svg {
  pointer-events: none;
}

.comp-input {
  width: 60px;
  margin-top: 1px;
  margin-left: 4px;
}

.comp-input::-webkit-inner-spin-button, .gt-input::-webkit-outer-spin-button, .lt-input::-webkit-inner-spin-button, .lt-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
